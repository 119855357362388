import { PageProps } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/Seo";

const NotFoundPage: React.FC<PageProps> = (props) => (
    <Layout>
        <SEO title="404: Not found" />
        <Section content first last>
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Section>
    </Layout>
);

export default NotFoundPage;
